.App {
  font-family: sans-serif;
  text-align: center;
}
.timeline {
  margin: 70px 60px;
  height: 3px;
  background-color: #ccc;
 
}

.timeline-progress {
  width: 3%;
  height: 100%;
  background-color: #1170E4;
}
.timeline-items {
  margin-left: -10px;
  margin-right: -12px;
  margin-top: -13px;
  display: flex;
  justify-content: space-between;
}

.timeline-item {
  position: relative;
  
}
.timeline-item::before {
  content: " ";
  width: 15px;
  height: 15px;
  margin-left: -10px;
  margin-right: 20px;
  background-color: #343A40;
  display: block;
  border-radius: 100%;
}
.timeline-contain {
  position: absolute;
  top: -37px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1170E4;
  
  width: 110px;
  padding: 5px 10px;
  border-radius: 15px;
  text-align: center;
}

.timeline-contain::before {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: #1170E4;
  display: block;
  position: absolute;
  top: 18px;
  margin-left: -2px;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%);
}
.timeline-item.process::before {
  background-color:#ddd615
}

.timeline-progress.process {
  width: 3%;
  height: 100%;
  background-color: #ddd615;
}

.timeline-item.active::before {
  background-color:#1170E4
}
.timeline-item.active .timeline-contain {
  color: #fff;
  background-color: #09AD95;
}
.timeline-item.active .timeline-contain::before {
  background-color: #09AD95;
}
